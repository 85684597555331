import { IAction, IBioMetrixInfo } from "../../type";

import {
  FC,
  useCallback,
  useState,
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useMemo,
} from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import cn from "classnames";

import { Loader, ReactModal, Skeleton } from "components";
import { numberValidate, numberDecimalValidate, useFormatNumber, mergeStringWithSymbol } from "utils";
import {
  ActiveCurrencyState,
  InfoState,
  IsOpenAssetLoadingState,
  SelectedOrderState,
  userSelectedCurrencyState,
  VisibleModifyState,
  defaultFormDatastate,
  goodTillCancelledState,
  userPersonalDetails,
  UsersLoadingState,
  VisibleCancelState,
  VerifyOrderModalType,
  VerfyOrderState,
} from "states";
import { useNetwork, useNotification, useThrottle, useWehAuthn } from "hooks";
import { APIS, Messages } from "constant";
import { actions, assestType, gov_guaranteed_loan, ORDERBOOK_SERIES } from "views/exchange/constants";
import { useCurrency } from "hooks/currency";
import { Confirmation, ReactSwitch } from "@storybook";

import styles from "./Action.module.sass";
import "./Action.scss";
import { UseTransaction } from "views/MyWallet/store/hooks";
import {
  AssetPortfolioState,
  OrderPlacedExecutedCheckState,
  OrderPlacedState,
} from "@views/exchange/components/Orders/store/state";
import { ORDER_TYPE } from "@views/MyOrders/constants";
import useAuctionHook from "@views/AuctionConsumer/hook";
import { DeviceBiometrics } from "@components/device-biometrics";
import { FortressAccountInfo } from "@views/MyWallet/store/state";
import BuysellConfirmation from "../buysellConfimation/buysellConfirmation";
import useMpcWalletApi from "@views/mpc-wallet/store/useMpcWalletApi";
import { useFullStoryTrackEvent } from "@hooks/useFullStoryTrack";
import InsufficientFunds from "@views/MyWallet/conponent/InsufficientFunds/insufficientFunds";
import classNames from "classnames";
import VerifyOrder from "../VerifyOrder/VerifyOrder";

const {
  ZERO_PRICE_ERROR,
  ZERO_LIMIT_ERROR,
  ZERO_QTY_ERROR,
  ORDER_PLACED,
  ORDER_EXECUTED,
  ORDER_ERROR_MESSAGE,
  LIMIT_BUY_MESSAGE,
  LIMIT_SELL_MESSAGE,
  ORDER_MODIFIED,
  BUYCONFIRMATION,
  SELLCONFIRMATION,
  ORDER_MODIFY_EXECUTED,
} = Messages;

enum ModalType {
  CONFIRM,
  APPROVED,
  DECLINE,
  TIMEUP,
  DEFAULT,
  MODIFIED,
  CANCELLED
}


export const Action: FC<IAction> = ({
  type,
  activeTabIndex,
  content,
  StopLimit,
  classButton,
  buttonText,
  isOrderModifying,
}) => {
  //global states
  const activeCurrency = useRecoilValue(ActiveCurrencyState);
  const setInfoData = useSetRecoilState(InfoState);
  const selectedOrder = useRecoilValue(SelectedOrderState);
  const setVisibleModify = useSetRecoilState(VisibleModifyState);
  const [goodTillCancelled, setGoodTillCancelled] = useRecoilState(
    goodTillCancelledState
  );
  // We need to update orderTab orderPlaceState to trigger order details api
  const [orderPlaced, setOrderPlaced] = useRecoilState(OrderPlacedState);
  const UserLoading = useRecoilValue(UsersLoadingState);


  const setorderPlacedExecutedCheck = useSetRecoilState(
    OrderPlacedExecutedCheckState
  );
  const personalData = useRecoilValue(userPersonalDetails);

  const { calculateWeightedYieldPer } = useAuctionHook();
  const { getWebAuthnSupported } = useWehAuthn();

  const currencyDetails = useRecoilValue(userSelectedCurrencyState);
  const isOpenAssetLoading = useRecoilValue(IsOpenAssetLoadingState);
  const formDataInitial = useRecoilValue(defaultFormDatastate);
  const [formData, setFormData] = useState(formDataInitial);
  const [visibleCancel, setVisibleCancel] = useState(false);
  const availableBalance = useRecoilValue<any>(FortressAccountInfo);
  const [isBalance,setIsBalance]=useState(false)

  const { phone, countryCode } = useRecoilValue(userPersonalDetails);
  const [isbiometricLoading, setIsbiometricLoading] = useState(false);
  const [initiateBiometric, setInitiateBiometrics] = useState(false);
  const { trackEvent } = useFullStoryTrackEvent();

  const bondPoint =
    formData.price &&
    typeof formData.price === "string" &&
    parseFloat(formData.price.split(".")[0]) > 1
      ? "bond points"
      : "bond point";

  useEffect(() => {
    if (formDataInitial.price) {
      setFormData((prev) => ({ ...prev, price: formDataInitial.price }));
    }
  }, [formDataInitial]);

  const {
    id: activeCurrencyId,
    marketPrice,
    symbol,
    amount,
    currentLoanBalance,
    issuerDetails,
    musicId,
    fee,
  }: any = activeCurrency ?? {};

  const { issuerName } = issuerDetails || {}
  //local state
  const [dateTime, setDateTime] = useState(0);
  const [isBtnDisable, setBtnDisable] = useState(false);
  const setModalType = useSetRecoilState<ModalType>(VerifyOrderModalType);
  const setOpenVerfyOrder = useSetRecoilState(VerfyOrderState);
  const setVisibleCancelOrder = useSetRecoilState(VisibleCancelState);

  // hooks
  const { post: postOrder, loading } = useNetwork();
  const { put: modifyOrder, loading: modifyLoading } = useNetwork();
  const { get: getInfo } = useNetwork();
  const { errorNotification, successNotification } = useNotification();
  const { getSignHash, getFirebaseShards } = useMpcWalletApi()
  const {
    currencyConvertInNumber,
    amountToBondPoints,
    bondPointToAmount,
    formatCurrencyWithBillion,
  } = useCurrency();
  const { numberDecimal } = useFormatNumber();
  const { getFortressAccountInfo } = UseTransaction();
  const assetPortfolio = useRecoilValue(AssetPortfolioState);

  const { get: getOrders } = useNetwork();
  const isOrderBook = activeCurrency?.sbaType === ORDERBOOK_SERIES;
  const isGovGuarunteed = activeCurrency?.loanType === gov_guaranteed_loan;

  const insufficientFundsArr=["Failed to create order.: You do not have enough balance in your wallet","Failed to create order......: You do not have enough balance in your wallet","Failed to create order: You do not have enough balance in your wallet","Failed to update order: You do not have enough balance in your wallet"]

  const assetPrice =
    activeCurrency?.type === assestType.SBA7
      ? isOrderBook ? amountToBondPoints(isGovGuarunteed ? selectedOrder?.government_guaranteed_loan_balance : selectedOrder?.current_loan_balance, selectedOrder?.price) : amountToBondPoints(currentLoanBalance, amount)
      : marketPrice || amount;
      

  useEffect(() => {
    if (assetPrice && symbol) {
      setFormData((prev: any) => {
        return {
          ...prev,
          symbol: isOrderBook ? selectedOrder?.symbol : symbol,
          price: isOrderModifying
            ? 
                  activeCurrency?.type === assestType.SBA7
                    ?( Number( amountToBondPoints(
                      isOrderBook ? isGovGuarunteed ? selectedOrder?.government_guaranteed_loan_balance : selectedOrder?.current_loan_balance 
                        : currentLoanBalance,
                      selectedOrder?.price
                      ))*currencyDetails?.rate).toFixed(6)
                    : (Number(selectedOrder?.price)
                * currencyDetails?.rate).toFixed(2)
            : activeCurrency?.type === assestType.SBA7 ? (assetPrice * currencyDetails?.rate).toFixed(6) : (assetPrice * currencyDetails?.rate).toFixed(2),
          qty: isOrderModifying ? Math.round(selectedOrder?.quantity) : "1",
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isOrderModifying,
    assetPrice,
    selectedOrder?.price,
    symbol,
    currencyDetails?.rate,
  ]);

  useEffect(() => {
    const { price, limit, qty } = formData;
    if (activeTabIndex === 2) {
      if (!parseFloat((price || 0) as string) || !qty || !limit) {
        return setBtnDisable(true);
      }
      setBtnDisable(false);
    } else {
      if (!parseFloat((price || 0) as string) || !qty) {
        return setBtnDisable(true);
      }
      setBtnDisable(false);
    }
  }, [activeTabIndex, formData]);

  const handleWallet = useCallback(
    (TotalAmount: number) => {
      if (type === "BUY") {
        setInfoData((prev) => {
          return { ...prev, balance: prev.balance - TotalAmount };
        });
      }
    },
    [setInfoData, type]
  );

  const handleSubmit = useCallback(
    async (
      price: string | number,
      qty: string | number,
      limit: string | undefined,
      bioMetrixInfo: IBioMetrixInfo | undefined
    ) => {
      if (Number(price) === 0) {
        return errorNotification(ZERO_PRICE_ERROR);
      }
      if (Number(qty) === 0) {
        return errorNotification(ZERO_QTY_ERROR);
      }
      if (StopLimit) {
        if (Number(limit) === 0) {
          return errorNotification(ZERO_LIMIT_ERROR);
        }
        if (type === actions.BUY && Number(limit) < Number(price)) {
          return errorNotification(LIMIT_BUY_MESSAGE);
        }
        if (type === actions.SELL && Number(limit) > Number(price)) {
          return errorNotification(LIMIT_SELL_MESSAGE);
        }
      }
      setVisibleCancelOrder(false);

      setFormData((prev: any) => {
        return {
          ...prev,
          symbol,
          // price: Number((assetPrice * currencyDetails.rate).toFixed(2)),
          price:
            activeCurrency?.type === assestType.SBA7
              ? (assetPrice * currencyDetails?.rate).toFixed(6)
              : (assetPrice * currencyDetails?.rate).toFixed(2),
        };
      });

      const url = isOrderModifying ?
      `${APIS.ORDERS_EXCHANGE_MODIFICATION}/${selectedOrder?.id}`
        // ? `${APIS.ORDERS_EXCHANGE_MODIFICATION}/${selectedOrder?.id}`
        : APIS.ORDERS_EXCHANGE;
      let payload = {};
      // this is to be used when SECRET_KEY from keychain will be available
      const SECRET_KEY: string = "";
      // const shardsData = await getFirebaseShards(personalData?.phone);
      // const shards = [shardsData?.shards1, shardsData?.shards2, shardsData?.shards3];
      // const signHashData = await getSignHash(shards, payload);
      const modifiedCurrency =
        activeCurrency?.type === assestType.SBA7
          ? Number(bondPointToAmount(isOrderBook ? isGovGuarunteed ? selectedOrder?.government_guaranteed_loan_balance : selectedOrder?.current_loan_balance : currentLoanBalance, price).toFixed(2) || 0)
          : Number(currencyConvertInNumber(price));
      if (type === "BUY") {
        payload = isOrderModifying
          ? {
              price: modifiedCurrency,
              quantity: qty,
              // signedTransactionHash: signHashData?.signature
            }
          : {
              assetId: activeCurrencyId,
              quantity: qty,
              price: modifiedCurrency,
              type,
              goodTillCancelled: goodTillCancelled,
            // signedTransactionHash: signHashData?.signature
            };
      } else {
        payload = isOrderModifying
          ? {
              price: modifiedCurrency,
              quantity: qty,
            // signedTransactionHash: signHashData?.signature
            }
          : {
              assetId: activeCurrencyId,
              quantity: qty,
              price: modifiedCurrency,
              type,
              goodTillCancelled: goodTillCancelled,
            // signedTransactionHash: signHashData?.signature
            };
      }

      if (SECRET_KEY?.length > 0) {
        payload = { ...payload, payer: SECRET_KEY };
      }

      setDateTime(new Date().getTime());
      setorderPlacedExecutedCheck(false);

      if (isOrderModifying) {
        let countOldOpenOrder = 0;
        let countOldExecutedOrder = 0;
        getOrders(
          `${APIS.ORDERS}?assetId=${activeCurrencyId}&type=${ORDER_TYPE.PENDING}&offset=0&limit=20`
        ).then((res: any) => {
            if (res) {
              countOldOpenOrder = res?.data?.length;
              getOrders(
                `${APIS.ORDERS}?assetId=${activeCurrencyId}&type=${ORDER_TYPE.EXECUTED}&offset=0&limit=20`
              ).then((res) => {
                if (res) {
                  countOldExecutedOrder = res?.data?.length;
                modifyOrder(url, payload).then((res) => {
                  if(res){
                    if(insufficientFundsArr.includes(res?.message)){
                      setIsBalance(true)
                          return;
                        }
                      }
                      if (res?.data?.orderRequestId) {
                        setOpenVerfyOrder({...res?.data,VerificationType : "modifyOrder"});
                        setModalType(ModalType.CONFIRM);
                      // if (res?.id) {
                        setVisibleModify(false);
                        getOrders(
                          `${APIS.ORDERS}?assetId=${activeCurrencyId}&type=${ORDER_TYPE.PENDING}&offset=0&limit=20`
                        ).then((res: any) => {
                          const updatedOpenOrder = res?.data?.length;
                          getOrders(
                            `${APIS.ORDERS}?assetId=${activeCurrencyId}&type=${ORDER_TYPE.EXECUTED}&offset=0&limit=20`
                          ).then((res: any) => {
                            if (res) {
                              const updatedExecutedOrder = res?.data?.length;
                              // if (
                              //   countOldOpenOrder > updatedOpenOrder ||
                              //   countOldExecutedOrder < updatedExecutedOrder
                              // ) {
                              //   successNotification(ORDER_MODIFY_EXECUTED);
                              // } else {
                              //   successNotification(ORDER_MODIFIED);
                              // }
                              setorderPlacedExecutedCheck(true);
                              setOrderPlaced(!orderPlaced);
                              trackEvent(`Order Modified`, {
                            ...payload
                              });
                              getInfo(APIS.Info).then((wallet) => {
                                if (wallet?.data?.stellar) {
                                  setInfoData(wallet?.data?.stellar);
                                }
                              });
                            }
                          });
                        });
                      } else {
                        errorNotification(res?.message ?? ORDER_ERROR_MESSAGE);
                      }
                }).catch((err) => {
                      errorNotification(err?.message ?? ORDER_ERROR_MESSAGE);
                    });
                }
              });
            }
        }).catch((error) => {
            console.error("Error retrieving old order count:", error);
          });
      } else {
        let countOldOpenOrder = 0;
        let countOldExecutedOrder = 0;
        getOrders(
          `${APIS.ORDERS}?assetId=${activeCurrencyId}&type=${ORDER_TYPE.PENDING}&offset=0&limit=20`
        ).then((res: any) => {
            if (res) {
              countOldOpenOrder = res?.data?.length;
              getOrders(
                `${APIS.ORDERS}?assetId=${activeCurrencyId}&type=${ORDER_TYPE.EXECUTED}&offset=0&limit=20`
              ).then((res) => {
                if (res) {
                  countOldExecutedOrder = res?.data?.length;
                postOrder(url, payload).then((res) => {
                  if(res){
                    if(insufficientFundsArr.includes(res?.message)){
                      setIsBalance(true)
                          return;
                        }
                      }
                      if (res?.data?.orderRequestId) {
                        setOpenVerfyOrder(res?.data);
                        setModalType(ModalType.CONFIRM);

                        const totalAmount = Number(price) * Number(qty);
                        handleWallet(totalAmount);
                        setFormData((prev) => ({
                          ...prev,
                          qty: 1,
                        }));
                        getOrders(
                          `${APIS.ORDERS}?assetId=${activeCurrencyId}&type=${ORDER_TYPE.PENDING}&offset=0&limit=20`
                    ).then((res: any) => {
                            if (res) {
                              const updatedOpenOrder = res?.data?.length;
                              getOrders(
                                `${APIS.ORDERS}?assetId=${activeCurrencyId}&type=${ORDER_TYPE.EXECUTED}&offset=0&limit=20`
                              ).then((res) => {
                                if (res) {
                                  const updatedExecutedOrder =
                                    res?.data?.length;
                                  // if (
                                  //   countOldOpenOrder === updatedOpenOrder ||
                                  //   countOldExecutedOrder < updatedExecutedOrder
                                  // ) {
                                  //   successNotification(ORDER_EXECUTED);
                                  // } else {
                                  //   successNotification(ORDER_PLACED);
                                  // }
                                  getFortressAccountInfo();
                                  setorderPlacedExecutedCheck(true);
                                  setOrderPlaced(!orderPlaced);
                                  trackEvent(`Order Placed`, {
                              ...payload
                                  });
                                  getInfo(APIS.Info).then((wallet) => {
                                    if (wallet?.data?.stellar) {
                                      setInfoData(wallet?.data?.stellar);
                                    }
                                  });
                                }
                              });
                            }
                    }).catch((error) => {
                            console.error(
                              "Error retrieving updated order count:",
                              error
                            );
                          });
                      } else {
                    errorNotification(res?.message?.replace(/\./g, ' ') ?? ORDER_ERROR_MESSAGE);
                      }
                }).catch((err) => {
                      errorNotification(err?.message ?? ORDER_ERROR_MESSAGE);
                    });
                }
              });
            }
        }).catch((error) => {
            console.error("Error retrieving old order count:", error);
          });
      }
    },
    [StopLimit, activeCurrency?.type, activeCurrencyId, assetPrice, bondPointToAmount, currencyConvertInNumber, currencyDetails?.rate, currentLoanBalance, errorNotification, getFortressAccountInfo, getInfo, getOrders, goodTillCancelled, handleWallet, insufficientFundsArr, isGovGuarunteed, isOrderBook, isOrderModifying, modifyOrder, orderPlaced, postOrder, selectedOrder?.current_loan_balance, selectedOrder?.government_guaranteed_loan_balance, selectedOrder?.id, setInfoData, setOrderPlaced, setorderPlacedExecutedCheck, symbol, trackEvent, type]
  );

  const handleSuccess = useCallback(
    (bioInfo?: IBioMetrixInfo) => {
      const { price, qty, limit } = formData ?? {};
      handleSubmit(Number(price), Number(qty), limit, bioInfo);
      setInitiateBiometrics(false);
    },
    [formData, handleSubmit]
  );

  const handleFailure = useCallback(() => {
    setInitiateBiometrics(false);
  }, []);

  const throttledErrorNotification = useThrottle((message:string) => {
    errorNotification(message);
  }, 2000);

  const checkDecimalPlaces = (value: string, maxPlaces: number) => {
    const decimalIndex = value.indexOf('.');
    if (decimalIndex !== -1) {
      const decimalPart = value.slice(decimalIndex + 1);
      return decimalPart.length <= maxPlaces;
    }
    return true;
  };

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, name: string) => {
      const { value } = e.target;
      const validQty = /^[0-9]{0,8}$/.test(value);
      const validPrice = /^(?!0{2,})[0-9]{0,10}(?:[.][0-9]{0,4})?$/.test(value);
      const validPriceForSba = /^(?!0{2,})[0-9]{0,10}(?:[.][0-9]{0,6})?$/.test(value);

      if (name === "qty" && !validQty) return;

      if (name === 'price') {
        if (activeCurrency?.type === assestType.SBA7) {
          if (!validPriceForSba) {
            if (!checkDecimalPlaces(value, 4)) {
            throttledErrorNotification(
              "You can only enter up to 4 decimal places."
            );
            }
            return;
          }
        } else {
          if (!validPrice) {
            if (!checkDecimalPlaces(value, 4)) {
              throttledErrorNotification(
                "You can only enter up to 4 decimal places. "
              );
            }
            return;
          }
        }
      }

      setFormData((prev: any) => ({ ...prev, [name]: value }));
    },
    [setFormData, activeCurrency?.type]
  );

  const handleKeyPress = useCallback(
    (e: KeyboardEvent<HTMLInputElement>, name: string) => {
      const { key } = e;
      if (name === "price") {
        if (!numberDecimalValidate(key)) {
          e.preventDefault();
        }
      }

      if (name === "limit") {
        if (key === "." && formData.limit?.toString().includes("."))
          e.preventDefault();
        else if (!numberDecimalValidate(key)) {
          e.preventDefault();
        }
      }

      if (name === "qty" && !numberValidate(key)) {
        e.preventDefault();
      }
    },
    [formData.limit]
  );

  const handleToggle = (checked: boolean) => {
    setGoodTillCancelled(checked);
  };

  const handleOrderAction = useCallback(async (choosePaymentType: string) => {
      const { price, limit, qty } = formData;
      if (
        type==="SELL" && isOrderModifying && (Number(qty) > (Number(assetPortfolio?.data?.quantity) + Number(selectedOrder.quantity)))
      ) {
        return errorNotification("Not enough quantity available");
      }
      if(type==="SELL" && ((Number(qty) > Number(assetPortfolio?.data?.quantity)) || !Number(assetPortfolio?.data?.quantity || 0)) && !isOrderModifying ){
        return errorNotification("Not enough quantity available");
      }
      trackEvent(`Exchange Trading`, {
        type: type,
        assetSymbol: symbol,
        assetId: activeCurrencyId,
      price, limit, qty
      });
      if (choosePaymentType === "wallet" && availableBalance?.balance < 0) {
        errorNotification("Wallet balance is not sufficient");
      } else {
        if (personalData?.isTradeEnabled === false) {
          return errorNotification("Trading is not allowed");
        }
        setIsbiometricLoading(true);
        if (!getWebAuthnSupported()) {
          handleSuccess();
        } else {
          setInitiateBiometrics(true);
        }
      }
  }, [availableBalance?.balance, errorNotification, personalData?.isTradeEnabled, getWebAuthnSupported, handleSuccess, formData]);

  const handleClose = useCallback(() => {
    setVisibleCancel(false);
    setInitiateBiometrics(false);
  }, []);

  const capitalizeFirstLetter = useCallback((str: string) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1).toLocaleLowerCase();
  }, []);

  const setModalVisible = useCallback(
    (loading: boolean, isModalOpen: boolean) => {
      setIsbiometricLoading(loading);
      setInitiateBiometrics(isModalOpen);
      setVisibleCancel(false);
    },
    []
  );

  const renderConfirmation = useMemo(() => {
    return (
      <BuysellConfirmation
        title={`${
          !isOrderModifying ? `Do you confirm to proceed with this ${capitalizeFirstLetter(type)} order?` 
            : "Modify"
        }`}
        formData={formData}
        visible={visibleCancel}
        description={type === "BUY" ? BUYCONFIRMATION : SELLCONFIRMATION}
        handleModal={handleOrderAction}
        handleClose={handleClose}
        isOrderModifying={isOrderModifying}
        label={type === "BUY" ? "BUY" : "SELL"}                                                
        type={type === "BUY"  ? "buy" : "sell"}
        cancelLoading={isbiometricLoading || modifyLoading || loading}
      />
    );
  }, [isOrderModifying, capitalizeFirstLetter, type, visibleCancel, handleOrderAction, handleClose, isbiometricLoading, modifyLoading, loading]);

  const handleWheel = (e: any) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };


  return (
    <>
      <div className={cn(styles.action ,{
                [styles.actionSba7]: activeCurrency?.type === assestType.SBA7,
              })}>
        <div className={styles.head}>
          <div className={styles.title}>
            {symbol === "" ? (
              <Skeleton />
            ) : musicId ? (
              symbol
            ) : (
              mergeStringWithSymbol(
                issuerName,
                isOrderBook ? selectedOrder?.symbol : symbol,
                "."
              )
            )}
          </div>
        </div>
        <div
          className={cn(styles.walletSection, {
            [styles.modifyingWalletSection]: isOrderModifying,
          })}
        >
          <div
            className={cn(styles.walletBalance, {
              [styles.modifyingWalletBalance]: isOrderModifying,
            })}
          >
            <div
              className={cn(styles.walletHeading, {
                [styles.modifyingWalletHeading]: isOrderModifying,
              })}
            >
              <i
                className="ri-wallet-3-line walletSvg"
                style={{ fontSize: "16px" }}
              />{" "}
              Wallet Balance :
            </div>
            <div className={styles.balanceValue}>{content}</div>
          </div>
          <div
            className={cn(styles.walletBalance, {
              [styles.modifyingWalletBalance]: isOrderModifying,
            })}
          >
            <div
              className={cn(styles.walletHeading, {
                [styles.modifyingWalletHeading]: isOrderModifying,
              })}
            >
              <i
                className="ri-coins-line walletSvg"
                style={{ fontSize: "16px" }}
              />
              Quantity Owned :
            </div>
            <div
              className={cn(styles.balanceValue, {
                [styles.modifyingAssetQty]: isOrderModifying,
              })}
            >
              {assetPortfolio?.loading ? <Skeleton height={15} /> : assetPortfolio?.data?.quantity ?? 0}
            </div>
          </div>
        </div>
        <>
          <label className={styles.field}>
            <div className={styles.label}>Price</div>
            {!isOpenAssetLoading ? (
              <input
                type="text"
                name="price"
                value={formData.price}
                maxLength={15}
                disabled={activeTabIndex === 1}
                className={styles.input}
                style={{
                  color: `${activeTabIndex === 1 ? "#777E90" : "unset"}`,
                }}
                min={1}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleChange(e, "price")
                }
                onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                  handleKeyPress(e, "price")
                }
                autoComplete="off"
              />
            ) : (
              <Skeleton />
            )}

            <div
              className={cn(styles.currency, {
                [styles.bondPoint]: activeCurrency?.type === assestType.SBA7,
              })}
            >
              {activeCurrency.type === assestType.SBA7
                ? bondPoint
                : currencyDetails.code}
            </div>
          </label>
        </>
        {activeCurrency.type === assestType.SBA7 && (
          <div
            className={cn(styles.walletBalance, {
              [styles.modifyingWalletBalance]: isOrderModifying,
            })}
          >
            <div
              className={cn(styles.walletHeading, {
                [styles.modifyingWalletHeading]: isOrderModifying,
              })}
            >
              {/* <i
              className="ri-wallet-3-line walletSvg"
              style={{ fontSize: "16px" }}
            />{" "} */}
              Equivalent Value :
            </div>
            {/* const amount = bondPointToAmount(auctionDetails?.currentLoanBalance,
            maxBidPrice) || 0; const avgYieldPer =
            calculateWeightedYieldPer(auctionDetails, amount, 6); return (
            <>
              Equivalent value : {formatCurrencyWithBillion(amount, 2, true)}
              <br />
              totalWeightedLoanBalance
              Equivalent weighted avg. yield : {avgYieldPer}%
            </> */}
            <div className={styles.balanceValue}>
              {formatCurrencyWithBillion(
                bondPointToAmount(isOrderBook ? isGovGuarunteed ? selectedOrder?.government_guaranteed_loan_balance : selectedOrder?.current_loan_balance : currentLoanBalance, formData?.price) || 0,
                2,
                true,
                true
              )}
            </div>
          </div>
        )}

        {StopLimit && (
          <label className={styles.field}>
            <div className={styles.label}>Limit</div>
            <input
              type="text"
              name="limit"
              value={formData.limit}
              className={styles.input}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChange(e, "limit")
              }
              onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                handleKeyPress(e, "limit")
              }
            />
            <div className={styles.currency}>
              {" "}
              {activeCurrency.type === assestType.SBA7
                ? "bond points"
                : currencyDetails.code}
            </div>
          </label>
        )}
        <label className={styles.field}>
          <div className={styles.label}>Qty</div>
          <input
            type="number"
            onWheel={handleWheel}
            name="qty"
            value={formData.qty}
            className={styles.input}
            min={1}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChange(e, "qty")
            }
            onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
              handleKeyPress(e, "qty")
            }
            disabled={activeCurrency.type === assestType.SBA7}
          />
        </label>
        <label className={styles.field}>
          <div className={styles.label}>Total</div>

          <div className={styles.total}>
            { activeCurrency?.type === assestType.SBA7?(Number(formData.price) * Number(formData.qty)).toFixed(6):(Number(formData.price) * Number(formData.qty)).toFixed(2)}
          </div>
          <div className={styles.currency}>
            {activeCurrency.type === assestType.SBA7
              ? bondPoint
              : currencyDetails.code}
          </div>
        </label>
        {activeCurrency.type === assestType.SBA7 && (
          <div
            className={cn(styles.walletBalance, {
              [styles.modifyingWalletBalance]: isOrderModifying,
            })}
          >
            <div
              className={cn(styles.walletHeading, {
                [styles.modifyingWalletHeading]: isOrderModifying,
              })}
            >
              {/* <i
              className="ri-wallet-3-line walletSvg"
              style={{ fontSize: "16px" }}
            />{" "} */}
              Equivalent yield :
            </div>
            <div className={styles.balanceValue}>
              {/* 10.260000% const */}
              {calculateWeightedYieldPer(
                {
                  metadata: {
                    totalWeightedLoanBalance:
                      activeCurrency?.totalWeightedLoanBalance,
                  },
                },
                bondPointToAmount(currentLoanBalance, formData?.price) || 0,
                6
              )}
              {""}%
            </div>
          </div>
        )}
        <p className={styles.fees}>1% fees is included in total</p>
        {!isOrderModifying && (
          <div className={styles.goodTillCancel}>
            <div>
              <p className={styles.goodTillCancelTitle}>
                Good till cancelled <i className="ri-information-fill"></i>
              </p>
              {goodTillCancelled ? (
                <p className={styles.goodTillCancelSubTitle}></p>
              ) : (
                <p className={styles.goodTillCancelSubTitle}>
                  This order will expire at 12:00 AM EST
                </p>
              )}
            </div>

            <div>
              <ReactSwitch
                checked={goodTillCancelled}
                handleChange={handleToggle}
                onColor="#3772FF"
                className={goodTillCancelled ? 'react-switch-handle-checked' : 'react-switch-handle-unchecked'}
              />
            </div>
          </div>
        )}
        <button
          disabled={ (type === "SELL" && !assetPortfolio?.data?.quantity && !isOrderModifying) ||
            isBtnDisable ||
            !(
              activeCurrency.type === assestType.privates ||
              activeCurrency.type === assestType.music ||
              activeCurrency.type === assestType.SBA7
            ) ||
            modifyLoading ||
            loading ||  Number(formData.qty) === 0 || UserLoading
          }
          onClick={() => setVisibleCancel(true)}
          className={cn(classButton, styles.button)}
        >
          {!loading && !modifyLoading ? (
            buttonText
          ) : (
            <Loader className="loader-white" dimension={30} />
          )}
        </button>
      </div>
      <ReactModal
        visible={visibleCancel}
        onClose={() => setVisibleCancel(false)}
        maskClosable={false}
        outerClassName={styles.confirmationModalOuter}
      >
        {renderConfirmation}
      </ReactModal>
      {initiateBiometric && (
        <DeviceBiometrics
          onSuccess={handleSuccess}
          onFailure={handleFailure}
          render={(isWebAuthLoading: boolean, isModalOpen: boolean) =>
            setModalVisible(isWebAuthLoading, isModalOpen)
          }
        />
      )}
    </>
  );
};
