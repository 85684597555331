import { useRecoilState, useRecoilValue } from "recoil";
import useDarkMode from "use-dark-mode";
import InfiniteScroll from "react-infinite-scroll-component";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  NOTIFICATION_TABS,
  NOTIFICATION_TYPE,
  emptyNotification,
} from "./constant";
import { Image } from "@storybook";
import AuthorisedNotification from "./components/AuthorisedNotification/authorisedNotification";
import OrderNotification from "./components/orderNotification/orderNotification";
import WalletNotification from "./components/walletNotification/walletNotification";
import BuysellNotification from "./components/buysellNotification/buysellNotification";
import BankNotification from "./components/bankNotification/bankNotification";
import UseNotificationApi from "./store/useNotificaitonApi";
import {
  NotificationDataState,
  activeNotifcationTabState,
} from "./store/state";
import { Json } from "../../types/common";
import { INotificationData } from "./store/type";
import { SkeletonWallet } from "@components/skeleton-loader";

import "./notification.scss";

const Notification = () => {
  const [activeTab, setActiveTab] = useRecoilState(activeNotifcationTabState);
  const [notificationData, setnotificationData] = useRecoilState<any>(NotificationDataState);
  const { fetchNotification } = UseNotificationApi();
  const darkMode = useDarkMode();
  const initialQuery = useMemo(() => {
    return { limit: 20, offset: 0 };
  }, []);
  useEffect(() => {
    setFilters(initialQuery);
  }, [initialQuery, activeTab]);

  const ordersForActiveTab =
    notificationData[activeTab as keyof typeof notificationData];
  const ordersLength = Array.isArray(ordersForActiveTab)
    ? ordersForActiveTab.length
    : 0;

  const [filters, setFilters] = useState(initialQuery);
  const handleTabChange = useCallback((key: string) => {
    setActiveTab(key);
  }, []);

  useEffect(() => {
    const options = { updateNewData: false };
    const query = {
      limit: 20,
      offset: 0,
      category: activeTab === "ALL" ? "" : activeTab,
    };
    fetchNotification(query, activeTab, options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const handleFetchMore = useCallback(async () => {
    let query: Json = {
      ...filters,
      offset: filters.offset + 1,
      category: activeTab === "ALL" ? "" : activeTab,
    };
    const options = { updateNewData: true };
    setFilters((prev) => ({ ...prev, offset: prev.offset + 1 }));
    fetchNotification(query, activeTab, options);
  }, [activeTab, filters]);

  const notificationMessage = useMemo(() => {
    if (activeTab === NOTIFICATION_TYPE.ALL) {
      return emptyNotification.NO_NOTIFICATION;
    } else if (activeTab === NOTIFICATION_TYPE.ORDERS) {
      return emptyNotification.NO_ORDERS_NOTIFICATION;
    } else if (activeTab === NOTIFICATION_TYPE.INVITE) {
      return emptyNotification.NO_INVITE_NOTIFICATION;
    } else {
      return emptyNotification.NO_MISCELLANEOUS_NOTIFICATION;
    }
  }, [activeTab]);

  const updateCoownerStaus = (id: string) => {
    const index = notificationData[activeTab].findIndex((item: Json) => item.metadata.id === id);

    if (index !== -1) {
      const updatedItem = {
        ...notificationData[activeTab][index],
        actionStatus: 'ACCEPTED'
      };

      const updatedInvite = [
        ...notificationData[activeTab].slice(0, index),
        updatedItem,
        ...notificationData[activeTab].slice(index + 1)
      ];

      setnotificationData((prevState: Json) => ({
        ...prevState,
        [activeTab]: updatedInvite
      }));
    }
  };
  
  const filteredNotifications = notificationData?.[activeTab as keyof INotificationData]?.filter(
    (notification: any) => !notification?.metadata?.requestTime
  );
  
  const isNotificationEmpty = filteredNotifications?.length === 0;

  return (
    <div className="notification_container">
      <div className="notifications-tabs">
        {NOTIFICATION_TABS.map((tab, index) => (
          <div
            key={tab.type}
            className={`notifications-tabs_item ${
              activeTab === tab.type ? "active" : ""
            }`}
            onClick={() => handleTabChange(tab.type)}
          >
            {tab.title}
          </div>
        ))}
      </div>
      {notificationData?.loading &&  isNotificationEmpty &&  (
    <div className="notification-empty-container">
      <Image
        className="no-data-image"
        fileName={
          darkMode.value
            ? "images/no_data_dark.svg"
            : "images/no_data_light.svg"
        }
      />
      <span className="order-empty-text">{notificationMessage}</span>
    </div>
  )} 

      <div id="scrollableDiv" className="infiniteScroll__container">
        <InfiniteScroll
          dataLength={ordersLength || 0}
          next={handleFetchMore}
          hasMore={
            ordersLength === filters.offset * filters.limit + filters.limit
          }
          scrollableTarget="scrollableDiv"
          loader={<SkeletonWallet listsToRender={3} />}
        >
          {notificationData[activeTab as keyof INotificationData]?.filter((notification:any)=>!notification?.metadata?.requestTime).map(
            (data: any) =>
              data?.category === "INVITE" ? (
                <AuthorisedNotification data={data} updateCoownerStatus={(value: string) => updateCoownerStaus(value)} />
              ) : data?.category === "ORDERS" ? (
                <OrderNotification data={data} />
              ) : data?.type === "WITHDRAWAL" || data?.type === "DEPOSIT" ? (
                <WalletNotification data={data} />
              ) : data?.type === "BANK_ACCOUNT" ? (
                <BankNotification data={data} />
              ) : data?.category === "buysell" ? (
                <BuysellNotification data={data} />
              ) : (
                ""
              )
          )}
        </InfiniteScroll>
        {notificationData?.loading ? (
          <div style={{ marginTop: "12px" }}>
            <SkeletonWallet listsToRender={3} />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Notification;
